import {mapActions} from "vuex";
import Breadcrumbs from '@/components/breadcrumbs/index.vue';
export default {
  name: 'delivery',
  data() {
    return {}
  },
  components: {Breadcrumbs},
  computed: {},
  methods: {
    ...mapActions({
      getDeliveryTypes: 'order/GET_DELIVERY_TYPES'
    })
  },
  created() {
    console.log(1);
    this.getDeliveryTypes()
  }
}

